@import 'styles/layout';

.root {
    @include grid;

    min-height: 75vh;
    place-items: center;
}

.message {
    grid-column: 1 / -1;
}
